import { gsap } from 'gsap';
import React, { useLayoutEffect, useRef } from 'react'
import { GSAP_CONFIG } from '../../configs/GsapConfig';

const createToForScene = (delay) => { 
  return {
    x: 100, // any properties (not limited to CSS)
    backgroundColor: "red", // camelCase
    duration: 1, // seconds
    delay: delay,
    ease: "power2.inOut",
    stagger: 0.1, // stagger start times
    paused: true, // default is false
    overwrite: "auto", // default is false
    repeat: 2, // number of repeats (-1 for infinite)
    repeatDelay: 1, // seconds between repeats
    repeatRefresh: true, // invalidates on each repeat
    yoyo: true, // if true > A-B-B-A, if false > A-B-A-B
    yoyoEase: true, // or ease like "power2"
    immediateRender: false,
    // onComplete: myFunc,
  }
}
export function mainScene(items) {
  let tl = gsap.timeline({ defaults: { duration: 1, ease: "elastic" } });
  
  items.forEach((item, index) => {
    console.log('item', item);
    tl.to(item, createToForScene(index));
  });
  return tl;
}

/*
  @class = 'GSap'
*/
export default function GsapContainer(props) {

  const main = useRef()

  const GetParamsGsap = (gsapType) => {
    return GSAP_CONFIG[gsapType] || GSAP_CONFIG['DEFAULT_GSAP']
  }

  const MakeGsap = (item) => {
    let gsapType = item.getAttribute('gsapType');
    let scrollTriger = item.getAttribute('scrollTriger') === 'true' ? true : false;
    let gsapRepeat = item.getAttribute('gsapRepeat') === 'true' ? true : false;
    let duration = item.getAttribute('gsapDuration') || 1;
    let params = GetParamsGsap(gsapType)

    console.log('params INIT', {
      gsapType,
      scrollTriger,
      sc: item.getAttribute('scrollTriger') === 'true',
      duration,
      gsapRepeat
    });

    if (!scrollTriger) {
      item.style.transition = duration + 's' || '1s'
      params.to.scrollTrigger.end = params.to.scrollTrigger.start
    } else {
      params.to.scrollTrigger.scrub = true
    }
    params.to.scrollTrigger = {
      ...params.to.scrollTrigger,
      trigger: item,
    }
    if (!gsapRepeat) {
      params.to.scrollTrigger.once = true
    }

    console.log('params', params.to);
    return gsap.fromTo(item, params.from, params.to);
  }

  

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      console.log('ctx', self);
      const gsapItems = self.selector('[gsapType]');
      // получить атрибут gsapType
      // const boxes = self.selector('.box');
      gsapItems.forEach(item => {
        console.log('item', item.getAttribute('gsapFromTo'));
        return MakeGsap(item)
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);
    // .play();

  return (
    <>
      <div ref={main} className='GSap'>
        {props.children}
      </div>
    </>
  )
}
