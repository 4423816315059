import { createSlice } from '@reduxjs/toolkit'
import { THEME_CONFIG } from '../../configs/AppConfig'

export const initialState = THEME_CONFIG
console.log(initialState);

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            if (state.themeType.includes(action.payload)) {
                state.theme = action.payload
            } else {
                state.theme = 'light'
            }
        },
    },
})

export const {
    setTheme
} = themeSlice.actions

export default themeSlice.reducer