import React from 'react'
import { Link, useParams } from 'react-router-dom'
import logo from '../../assets/img/header__logo.svg'
import s from './SiteMaker.module.scss'

export default function SiteMaker() {
    const { id } = useParams() 
    // id = id || 'no-id'
    return (
        <Link to={`https://itab.studio?utm_source=${id}`}
            target='_blank'
            className={s['maker']}>
            <span>Дизайн и разработка сайта:</span>
            <img src={logo} alt="itab studio" />
        </Link>
    )
}
