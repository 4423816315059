// const GSAP_
export const GSAP_CONFIG = {
    BlinkTo: {
        from: {
            opacity: 0,
            scale: 0.8,
            // transition: '0.25s',
        },
        to: {
            ease: "power1.inOut",
            opacity: 1,
            scale: 1,
            scrollTrigger: {
                start: 'top 70%',
                end: 'top 50%',
                scrub: true,
                markers: true,
                
                // from Component
                // @ trigger
                // @ scrub
            },
        }
    },
    leftSide: {
        from: {
            x: -200,
            // y: 0,
            opacity: 0,
        },
        to: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: "power1.inOut",
            scrollTrigger: {
                ease: "power1.inOut",
                start: 'top 70%',
                end: 'top 50%',
                markers: true,
            },
        }
    },
    rightSide: {
        from: {
            x: 200,
            // y: 0,
            opacity: 0,
        },
        to: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: "power1.inOut",
            scrollTrigger: {
                ease: "power1.inOut",
                start: 'top 70%',
                end: 'top 50%',
                markers: true,
            },
        },
    },

    bottomSide: {
        from: {
            y: "20",
            // y: 0,
            opacity: 0,
        },
        to: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: "power4.inOut",
            scrollTrigger: {
                start: 'top 70%',
                end: 'top 50%',
                markers: true,
                
            },
        },
    },
    topSide: {
        from: {
            y: "-20",
            // y: 0,
            opacity: 0,
        },
        to: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: "power4.inOut",
            scrollTrigger: {
                start: 'top 70%',
                end: 'top 50%',
                markers: true,

            },
        },
    },



    bottomSideB: {
        from: {
            y: "20",
            // y: 0,
            opacity: 0,
        },
        to: {
            x: 0,
            y: 0,
            opacity: 1,
            ease: "power4.inOut",
            scrollTrigger: {
                start: 'center 50%',
                end: 'center 50%',
                markers: true,

            },
        },
    },


}

export const DEFAULT_GSAP = 'BlinkTo'