import React, { useEffect } from 'react'
import { ThemeContext } from '../../../App'
import CaseBox from '../../CaseBox/CaseBox'
import './WebAppForStartup.modules.scss'

import case_1 from '../../../assets/img/brend__item-img2.jpg'
import Translation_ from '../../../Translater'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import GsapContainer from '../../../modules/GsapContainer/GsapContainer'

import ass from '../../../assets/img/corp_icon/copm.svg'

import copm from '../../../assets/img/corp_icon/copm.svg'
import copm2 from '../../../assets/img/corp_icon/copm2.svg'
import copm3 from '../../../assets/img/corp_icon/copm3.svg'
import copm4 from '../../../assets/img/corp_icon/copm4.svg'
import copm5 from '../../../assets/img/corp_icon/copm5.svg'
import copm6 from '../../../assets/img/corp_icon/copm6.svg'
import copm7 from '../../../assets/img/corp_icon/copm7.svg'
import copm8 from '../../../assets/img/corp_icon/copm8.svg'
import copm9 from '../../../assets/img/corp_icon/copm9.svg'
import copm10 from '../../../assets/img/corp_icon/copm10.svg'
import copm11 from '../../../assets/img/corp_icon/copm11.svg'
import copm12 from '../../../assets/img/corp_icon/copm12.svg'
import copm13 from '../../../assets/img/corp_icon/copm13.svg'
import copm14 from '../../../assets/img/corp_icon/copm14.svg'
import copm15 from '../../../assets/img/corp_icon/copm15.svg'
import copm16 from '../../../assets/img/corp_icon/copm16.svg'
import copm17 from '../../../assets/img/corp_icon/copm17.svg'

import clients__1 from '../../../assets/img/clients/clients__1.svg'
import { useDispatch } from 'react-redux'


const copsIcon = [
    copm,
    copm2,
    copm3,
    copm4,
    copm5,
    copm6,
    copm7,
    copm8,
    copm9,
    copm10,
    copm11,
    copm12,
    copm13,
    copm14,
    copm15,
    copm16,
    copm17,
]

gsap.registerPlugin(ScrollTrigger);

export default function WebAppForStartup() {
    const page_in_lg = 'webAppStartup'
    const dispath = useDispatch()
    const setTheme = (theme) => {
        dispath({ type: 'theme/setTheme', payload: theme })
    }
    setTheme('dark')
    const { t, i18n } = useTranslation();
    const lang_arr = i18n.logger.options.resources[i18n.language].translation
    console.log(lang_arr);
    let web_arr = []
    let additional_arr = []
    try {
        web_arr = lang_arr.services[page_in_lg]
        additional_arr = lang_arr.services[page_in_lg].additional.services
    } catch {
        web_arr = i18n.logger.options.resources['en'].translation.services[page_in_lg]
        additional_arr = i18n.logger.options.resources['en'].translation.services[page_in_lg].additional.services
    }

    // gsap.registerPlugin('ScrollSmoother')
    // ScrollSmoother.create({
    //   wrapper: document.body,
    //   content: document.querySelector('#root'),
    // })
    // gsap.registerPlugin(ScrollTrigger)


    useEffect(() => {
        // все дивы с классом start__copman-svg

        const copm__svg = document.querySelectorAll('.copm__svg').forEach((item, index) => {
            const random = (Math.random() * (3 - 1.6) + 1.6).toFixed(2)
            item.style = `animation: blink ${random}s ease-in-out infinite;`
        })
    }, [])

    const items = gsap.utils.toArray('.approach');
    // items.forEach(item => {
    //     gsap.fromTo(item, { opacity: 0, x: -100 }, {
    //         opacity: 1,
    //         x: 0,
    //         // x: 150,
    //         scrollTrigger: {
    //             trigger: item,
    //             // start: 'center',
    //             end: 'top 30%',
    //             // end: 'top 20%',
    //             // scrub: true,
    //         },
    //     });
    // });
    
    return (
        
            <>

                <section class="section__brend">

                    <div class="container">

                        <div class="header__content mainScene" id="header__content-black">
                            <Translation_ type='html' path={`services.${page_in_lg}.title`} />

                        </div>


                        <p class="brend__text mainScene">
                            <Translation_ path={`services.${page_in_lg}.desc`} />
                        </p>
                        <CaseBox items={[
                            {
                                title: 'Web App Development',
                                subtitle: 'subtitle',
                                poster: case_1,
                                video: 'https://files.itabstudio.com/NBCU_teaser_540.mp4',
                                // https://videos.ctfassets.net/1bms5cn3aemm/4BK64T6EOAfnMITgmJwbRm/54b9abbb663274162b6392b9de44ca86/NBCU_teaser_540.mp4',
                                index: '1',
                                to: '/case/vtb'
                            },
                            {
                                title: 'itab',
                                subtitle: 'asasd',
                                poster: case_1,
                                index: '1',
                                to: 'https://itab.studio/'
                            },
                            {
                                title: 'Web App Development',
                                subtitle: 'subtitle',
                                poster: case_1,
                                video: 'https://videos.ctfassets.net/1bms5cn3aemm/4BK64T6EOAfnMITgmJwbRm/54b9abbb663274162b6392b9de44ca86/NBCU_teaser_540.mp4',
                                index: '1',
                                to: '/case/vtb'
                            }
                        ]} />
                    </div>
                </section>

                <section class="section__desc">
                    <div class="container">


                        <div
                            gsapType='BlinkTo' scrollTriger='false' gsapDuration='0.6' gsapRepeat='false'
                            class="approach">

                            <p class="approach__title">
                                <Translation_ path={`services.${page_in_lg}.approach.title`} />
                            </p>

                            <p class="approach__subtitle">
                                <Translation_ path={`services.${page_in_lg}.approach.desc`} />
                            </p>
                        </div>

                        <div gsapType='bottomSide' scrollTriger='false' gsapDuration='0.2' gsapRepeat='false'
                            
                            class="approach">

                            <p class="approach__title">
                                <Translation_ path={`services.${page_in_lg}.capabilities.title`} />
                            </p>

                            <p class="approach__subtitle">
                                <Translation_ path={`services.${page_in_lg}.capabilities.desc`} />
                            </p>
                        </div>


                        <div class="strategy c2" >

                            <div class="strategy__item">
                                <p class="strategy__item-title">Front-end</p>
                                {web_arr['front-end'].map((item, index) => {
                                    return (<p class="strategy__item-subtitle">
                                        <Translation_ path={`services.${page_in_lg}.front-end.${index}`} />
                                    </p>)
                                })}
                            </div>

                            <div class="strategy__item">
                                <p class="strategy__item-title">Back-end</p>
                                {web_arr['back-end'].map((item, index) => {
                                    return (<p class="strategy__item-subtitle">
                                        <Translation_ path={`services.${page_in_lg}.back-end.${index}`} />
                                    </p>)
                                })}
                            </div>

                        </div>

                        <div class="approach">

                            <p class="approach__title">
                                <Translation_ path={`services.${page_in_lg}.additional.title`} />
                            </p>

                            <p class="approach__subtitle">
                                <Translation_ path={`services.${page_in_lg}.additional.desc`} />
                            </p>
                        </div>

                        <div class="addService strategy">
                            {additional_arr.map((item, index) => {
                                return (
                                    <div class="strategy__item">

                                        <div class="strategy__item-label">
                                            <Link class="strategy__item-title"
                                                to={Translation_({
                                                    type: 'tt',
                                                    path: `services.${page_in_lg}.additional.services.${index}.link`
                                                })}>
                                                <Translation_ type='tt' path={`services.${page_in_lg}.additional.services.${index}.title`} />
                                            </Link>
                                        </div>

                                        <p class="strategy__item-subtitle">
                                            <Translation_ path={`services.${page_in_lg}.additional.services.${index}.desc`} />
                                        </p>
                                    </div>
                                )
                            })}


                        </div>

                        <div class="approach">

                            <p class="approach__title"><Translation_ path={`services.${page_in_lg}.approach_end.title`} /></p>

                            <p class="approach__subtitle">
                                <Translation_ path={`services.${page_in_lg}.approach_end.desc`} />
                            </p>

                        </div>

                        <div class="copm">
                            {/* for 0 to 17 */}
                            {Array.from(Array(7), (e, i) => {
                                return (
                                    <img src={copsIcon[i]} class="copm__svg" alt="svg" />
                                )
                            })}
                        </div>


                    </div>
                </section>


                <section class="section__clients">
                    <div class="container">

                        <p class="clients__title"><Translation_ path={`services.${page_in_lg}.feedbacks.title`} /></p>

                        <div class="clients">

                            <div class="clients__item">

                                <p class="clients__item-title">
                                    <Translation_ path={`services.${page_in_lg}.feedbacks.all.0.text`} />
                                </p>

                                <div class="clients__item-info">
                                    <img src={clients__1} alt="svg" />

                                    <div class="clients__item-info-titles">
                                        <p class="clients__item-info-titles-title">
                                            <Translation_ path={`services.${page_in_lg}.feedbacks.all.0.name`} />
                                        </p>
                                        <p class="clients__item-info-titles-subtitle">
                                            <Translation_ type='html' path={`services.${page_in_lg}.feedbacks.all.0.role`} />
                                        </p>
                                    </div>

                                </div>

                            </div>

                            <div class="clients__item">

                                <p class="clients__item-title">
                                    <Translation_ path={`services.${page_in_lg}.feedbacks.all.1.text`} />
                                </p>

                                <div class="clients__item-info">
                                    <img src={clients__1} alt="svg" />

                                    <div class="clients__item-info-titles">
                                        <p class="clients__item-info-titles-title">
                                            <Translation_ path={`services.${page_in_lg}.feedbacks.all.1.name`} />
                                        </p>
                                        <p class="clients__item-info-titles-subtitle">
                                            <Translation_ type='html' path={`services.${page_in_lg}.feedbacks.all.1.role`} />
                                        </p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </section>
            </>
    )
}
