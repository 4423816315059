import { Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";


export default function Video({ video, poster, reff, onHover }) {
    let [onLoadedVideo, setOnLoadedVideo] = React.useState(false)

    useEffect(() => {
        console.log(0, reff.current.readyState);
        const RTS = () => {
            setTimeout(() => {
                if (reff.current.readyState === 4) {
                    console.log('onLoadedVideo', onLoadedVideo);
                    setOnLoadedVideo(true)
                } else {
                    RTS()
                }
            }, 1000);
        }
        RTS()

    }, [reff.current])
    video = video ? video : false
    return (
        <>
            <div style={{
                'border-radius': '0.6rem'
            }}>
                {poster ?
                    <img
                        style={{
                            'display': (!onHover || video===false) ? 'block' : 'none'
                        }}
                        ref={reff} src={poster} alt="img" class="brend__item-img" />
                    : null
                }

                {video &&
                    <>
                        {!onLoadedVideo ?
                            <Skeleton.Image style={{
                                width: '100%',
                                // display: !onLoadedVideo ? 'flex' : 'none'
                            }} loading={true} active />
                            : null}
                        <video style={{
                            'width': '100%',
                            'display': (onHover || !poster) && onLoadedVideo ? 'block' : 'none'
                        }}
                            ref={reff}
                            muted="muted">
                            <source src={video} type="video/mp4"></source>
                        </video>
                    </>
                }
            </div>
        </>
    );
}
