import { useTranslation } from "react-i18next";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// i18n.changeLanguage('en');

export default function Translation_({ path, type='text' }) {
    const { t, i18n } = useTranslation();

    let trans = t(path);
    if (trans.includes(path)) {
        trans = path;
    }

    if (trans == path) { 
        let rs = path.split('.');
        let lastWord = rs[rs.length - 1]
        let lg = i18n.language;
        let lang = require(`./translations/${lg}.json`);
        let langMain = require(`./translations/en.json`);
        let r = langMain['translation']

        // 'mainPage', 'startup', 'talk'
        for (let i = 0; i < rs.length - 1; i++) {
            // console.log(rs[i]);
            // console.log(r[rs[i]]);
            r = r[rs[i]] || {};
        }
        if (r[lastWord]) {
            trans = r[lastWord];
        }
    }

    // console.log(i18n.language);
    // console.log(t(path));
    // console.log(i18next.t('header.Services'));
    // console.log(i18next.t('mainPage.header.main'));
    if (type === 'html') {
        trans = ReactHtmlParser(trans)
    }
    if (type === 'tt') { 
        return trans
    }

    return <>{trans}</>
}

