import React, { useState, useEffect, useLayoutEffect } from 'react'
// import { Link } from 'react-router-dom'
import { Link } from '../../modules/Link/Link'
import "./MainPage.modules.scss"
import logo from '../../assets/img/header__logo.svg'
import start1 from '../../assets/img/start1.svg'
import start2 from '../../assets/img/start2.svg'
import start3 from '../../assets/img/start3.svg'
import start4 from '../../assets/img/start4.svg'
import start5 from '../../assets/img/start5.svg'
import start6 from '../../assets/img/start6.svg'
import start7 from '../../assets/img/start7.svg'
import start8 from '../../assets/img/start8.svg'
import start9 from '../../assets/img/start9.svg'
import start10 from '../../assets/img/start10.svg'
import start11 from '../../assets/img/start11.svg'
import start12 from '../../assets/img/start12.svg'


import button_copm from '../../assets/img/button_copm.svg'
import wb from '../../assets/img/wb.svg'
import vtbConnect from '../../assets/img/vtbConnect.svg'
import ny from '../../assets/img/nonton.svg'
import { ThemeContext } from '../../App'

import { Translation } from 'i18nano'
import Translation_ from '../../Translater'
import Animation, { AnimationGSAP } from '../../modules/Animation/Animation'
import { useDispatch } from 'react-redux'


export default function Header({ setModalActive }) {

    const animData = React.useRef();
    const [menuActive, setMenuActive] = useState(false)
    const dispath = useDispatch()
    const setTheme = (theme) => {
            dispath({ type: 'theme/setTheme', payload: theme })
    }


    // при полной отрисовке компонента
    useEffect(() => {
        setTheme('light')
        // все дивы с классом start__copman-svg
        const start__copman_svg = document.querySelectorAll('.start__copman-svg').forEach((item, index) => {
            const random = (Math.random() * (3 - 1.6) + 1.6).toFixed(2)
            item.style = `animation: blink ${random}s ease-in-out infinite;`
        })
    }, [])



    return (
        <>
            <div className='container'>
                <Animation delay={400}>
                    <section class="section__header">
                        <Translation_ type='html' path='mainPage.header.main' />
                    </section>
                </Animation>

                <Animation delay={900}>
                    <section class="section__start">
                        <div class="start">
                            <div class="start__item">
                                <p class="start__item-title section__title ss" style={{
                                    fontFamily: 'Montserrat',
                                }}>
                                    <Translation_ type='html' path='mainPage.business.main' />
                                </p>


                                <p class="start__item-subtitle">
                                    <Translation_ type='html' path='mainPage.business.desc' />
                                </p>

                                <div class="start__item-links">
                                    <label class="link-hover">
                                        <Link to={"/business/branding"} class="start__item-links-link">
                                            <Translation_ path='mainPage.business.Branding' />
                                            <hr class="start__item-links-link-hr" />
                                        </Link>
                                    </label>

                                    <label class="link-hover">
                                        <Link to={"/business/website"} class="start__item-links-link">
                                            <Translation_ path='mainPage.business.Marketing websites' />
                                            <hr class="start__item-links-link-hr" />
                                        </Link>
                                    </label>

                                    <label class="link-hover">
                                        <Link to={"/business/ui_ux"} class="start__item-links-link">
                                            <Translation_ path='mainPage.business.UI/UX' />
                                            <hr class="start__item-links-link-hr" />
                                        </Link>
                                    </label>


                                </div>

                            </div>
                            <div class="start__copman">
                                <img src={start1} class="start__copman-svg" alt="svg" />
                                <img src={start2} class="start__copman-svg" alt="svg" />
                                <img src={start3} class="start__copman-svg" alt="svg" />
                                <img src={start4} class="start__copman-svg" alt="svg" />
                                <img src={start5} class="start__copman-svg" alt="svg" />
                                <img src={start6} class="start__copman-svg" alt="svg" />
                                <img src={start7} class="start__copman-svg" alt="svg" />
                                <img src={start8} class="start__copman-svg" alt="svg" />
                                <img src={start9} class="start__copman-svg" alt="svg" />
                                <img src={start10} class="start__copman-svg" alt="svg" />
                                <img src={start11} class="start__copman-svg" alt="svg" />
                                <img src={start12} class="start__copman-svg" alt="svg" />
                                <Link to={"/contact/"} class="start__copman-button">
                                    <img src={button_copm} class="start__copman-button-svg" alt="svg" />
                                    <p class="start__copman-button-text">
                                        <Translation_ path='mainPage.business.talk' />
                                    </p>
                                </Link>
                            </div>

                        </div>
                    </section>
                </Animation>

                <Animation delay={100}>
                    <section class="section__static">
                        <div class="static">

                            <div class="static__item">

                                <p class="static__item-title"><Translation_ path='mainPage.cases_1.take' /></p>
                                <p class="static__item-subtitle"><Translation_ path='mainPage.cases_1.desc' /></p>
                                <p class="static__item-text">
                                    <Translation_ path='mainPage.cases_1.desc2' />
                                </p>


                                <div class="static__item-info">

                                    <label class="static__label">
                                        <Link to={"/cases/wildberries"} class="static__item-info-link">
                                            <Translation_ path='mainPage.cases_1.more' />
                                            <hr class="static__item-info-link-hr" />
                                        </Link>
                                    </label>


                                    <img src={wb} alt="img" />

                                </div>

                            </div>

                            <div class="static__item">
                                <p class="static__item-title">
                                    <Translation_ path='mainPage.cases_2.take' />
                                </p>
                                <p class="static__item-subtitle"><Translation_ path='mainPage.cases_2.desc' /></p>
                                <p class="static__item-text">
                                    <Translation_ path='mainPage.cases_2.desc2' />
                                </p>


                                <div class="static__item-info">

                                    <label class="static__label">
                                        <Link to={"/cases/nonton"} class="static__item-info-link">
                                            <Translation_ path='mainPage.cases_2.more' />
                                            <hr class="static__item-info-link-hr" />
                                        </Link>
                                    </label>

                                    <img src={ny} alt="img" />

                                </div>

                            </div>

                        </div>
                    </section>
                </Animation>

                <Animation delay={100}>
                    <section class="section__start">
                        <div class="start">
                            <div class="start__item">

                                <p class="start__item-title section__title" style={{
                                    fontFamily: 'Montserrat'
                                }}>
                                    <Translation_ type='html' path='mainPage.startup.main' />
                                </p>

                                <p class="start__item-subtitle">
                                    <Translation_ type='html' path='mainPage.startup.desc' />
                                </p>

                                <div class="start__item-links">
                                    <label class="link-hover">
                                        <Link to={"/startup/branding"} class="start__item-links-link">
                                            <Translation_ path='mainPage.startup.Branding' />
                                            <hr class="start__item-links-link-hr" />
                                        </Link>
                                    </label>

                                    <label class="link-hover">
                                        <Link to={"/startup/website"} class="start__item-links-link">
                                            <Translation_ path='mainPage.startup.web' />
                                            <hr class="start__item-links-link-hr" />
                                        </Link>
                                    </label>

                                    <label class="link-hover">
                                        <Link to={"/startup/ui_ux"} class="start__item-links-link">
                                            <Translation_ path='mainPage.startup.UI/UX' />
                                            <hr class="start__item-links-link-hr" />
                                        </Link>
                                    </label>


                                </div>

                            </div>
                            <div class="start__copman">
                                <img src={start1} class="start__copman-svg" alt="svg" />
                                <img src={start2} class="start__copman-svg" alt="svg" />
                                <img src={start3} class="start__copman-svg" alt="svg" />
                                <img src={start4} class="start__copman-svg" alt="svg" />
                                <img src={start5} class="start__copman-svg" alt="svg" />
                                <img src={start6} class="start__copman-svg" alt="svg" />
                                <img src={start7} class="start__copman-svg" alt="svg" />
                                <img src={start8} class="start__copman-svg" alt="svg" />
                                <img src={start9} class="start__copman-svg" alt="svg" />
                                <img src={start10} class="start__copman-svg" alt="svg" />
                                <img src={start11} class="start__copman-svg" alt="svg" />
                                <img src={start12} class="start__copman-svg" alt="svg" />
                                <Link to={"/contact/"} class="start__copman-button">
                                    <img src={button_copm} class="start__copman-button-svg" alt="svg" />
                                    <p class="start__copman-button-text">
                                        <Translation_ path='mainPage.startup.talk' />
                                    </p>
                                </Link>
                            </div>

                        </div>
                    </section>
                </Animation>

                <Animation delay={100}>

                    <section class="section__static" style={{
                        paddingBottom: "120px"
                    }}>
                        <div class="static">

                            <div class="static__item">



                                <p class="static__item-title">
                                    <Translation_ path='mainPage.cases_3.take' />
                                </p>
                                <p class="static__item-subtitle">
                                    <Translation_ path='mainPage.cases_3.desc' />
                                </p>
                                <p class="static__item-text">
                                    <Translation_ path='mainPage.cases_3.desc2' />
                                </p>

                                <div class="static__item-info">

                                    <label class="static__label">
                                        <Link to={"/cases/vtbconnect"} class="static__item-info-link">
                                            <Translation_ path='mainPage.cases_3.more' />

                                            <hr class="static__item-info-link-hr" />
                                        </Link>
                                    </label>


                                    <img src={vtbConnect} alt="img" />

                                </div>

                            </div>

                            <div class="static__item">
                                <p class="static__item-title">
                                    <Translation_ path='mainPage.cases_4.take' />

                                </p>
                                <p class="static__item-subtitle">
                                    <Translation_ path='mainPage.cases_4.desc' />

                                </p>
                                <p class="static__item-text">
                                    <Translation_ path='mainPage.cases_4.desc2' />
                                </p>

                                <div class="static__item-info">

                                    <label class="static__label">
                                        <Link to={"/cases/nonton"} class="static__item-info-link">
                                            <Translation_ path='mainPage.cases_4.more' />
                                            <hr class="static__item-info-link-hr" />
                                        </Link>
                                    </label>

                                    <img src={ny} alt="img" />

                                </div>

                            </div>

                        </div>
                    </section>
                </Animation>
            </div>
        </>
    )
}