import React from 'react'

import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import Translation_ from '../../Translater';
const { Dragger } = Upload;


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


const CustomReq = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const img = document.createElement('img');
        img.src = reader.result;
        // console.log(img);
        console.log('send res');
        // setImageUrl(reader.result);
        // return reader.result;
    }
}

const props = {
    name: 'file',
    multiple: true,
    action: '/',
    onChange(info) {
        console.log(info);
        getBase64(info.file.originFileObj).then((base64) => {
            info.file.base64 = base64
            info.file.status = 'done'
        })

        let { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },

    customRequest(e) {
        // CustomReq(e.file)
        // console.log(e.file);
        return true
    },
};

export default function UploadFile() {

    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                <Translation_ path="UploadFile.desc1" />
            </p>
            <p className="ant-upload-hint">
                <Translation_ path="UploadFile.desc2" />
            </p>
        </Dragger>
    )
}
