import React, { useEffect } from 'react'
import Header from '../../Header/Header'
import { ThemeContext } from '../../../App'
import MainBlock from './MainBlock/MainBlock'
import s from './Blogs.module.scss'
import { Divider, Skeleton, Space } from 'antd'
import { Link } from 'react-router-dom'
import { BarLoader, DotLoader, GridLoader, HashLoader } from 'react-spinners'
import DocViewer, { PDFRenderer, PNGRenderer } from "react-doc-viewer";
import { useDispatch } from 'react-redux'


export default function Blogs() {
    const dispath = useDispatch()
    const setTheme = (theme) => {
        dispath({ type: 'theme/setTheme', payload: theme })
    }
    setTheme('light')
    const [posts, setPosts] = React.useState([])
    useEffect(() => {
        fetch('http://api.itab.studio/v2/posts/')
            .then(res => res.json())
            .then(data => {
                setTimeout(() => {
                    setPosts(data.posts)
                }, 500)
                // console.log(data.posts);
            })
    }, [])

    return (
        <>
            <Header />
            <div className={s['nes']}>
                <div className={s['menu']}>
                    <div className={s['items']}>
                        <Link className={s['ds']}>All Posts</Link>
                        <Link className={s['ds']}>App Design</Link>
                        <Link className={s['ds']}>Design System</Link>
                        <Link className={s['ds']}>Branding</Link>
                        <Link className={s['ds']}>Startup</Link>
                        <Link className={s['ds']}>UI/UX Design</Link>
                        <Link className={s['ds']}>Web App Development</Link>
                        <Link className={s['ds']}>Web Design</Link>
                    </div>
                </div>
                <Divider className={s['line']} />
                <MainBlock posts={posts} loading={posts.length > 0} />
                <Divider />
            </div>
        </>
    )
}
