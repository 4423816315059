import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './CaseBox.modules.scss'
import Icon from '@ant-design/icons';
import Video from '../Video/Video';


const OtherLinkOutlinedSVG = () => (
    <svg width="1rem" height="1rem" viewBox="0 0 24 24" fill='currentColor' xmlns="http://www.w3.org/2000/svg">
        <path d="M23.34.816v19.632h-7.241v-3.43l.271-3.712-.226-.136-3.44 3.802L5.577 24.1l-5.52-5.52 7.128-7.13 3.801-3.439-.136-.226-3.71.272H3.707V.816z"></path>
    </svg>
);
const OtherLinkOutlined = (props) => <Icon component={OtherLinkOutlinedSVG} {...props} />;


function Case({ to, title = 'title', subtitle = 'subtitle', poster, video, index = '1' }) {
    let otherLink = to.includes('http')
    const [statusVideo, setStatusVideo] = React.useState(false)
    const refVideo = React.useRef(null);

    useEffect(() => {
        console.log(refVideo);
    }, [refVideo.current]);

    // refVideo.current.play();
    //     } else {
    //         try {
    //             refVideo.current.pause();
    //             refVideo.current.currentTime = 0;
    //         }catch(e){

    return (
        <div class="brend__item" index={index}>
            <Link to={to}
                onMouseOver={() => {
                    try {
                        refVideo.current.play();
                    } catch (e) { }
                }}

                onMouseOut={() => {
                    try {
                        refVideo.current.pause();
                        refVideo.current.currentTime = 0;
                    } catch (e) { }
                }}

            >
                <div className='imgs'>
                    <Video
                        reff={refVideo}
                        statusVideo={statusVideo}
                        poster={poster}
                        video={video} />
                </div>

                <p class="brend__item-title">
                    <div>{title}</div>
                    {otherLink ? <OtherLinkOutlined
                        style={{
                            fontSize: '32px',
                            color: 'white',
                            paddingBottom: '10px'
                        }} /> : null}
                </p>

                <p class="brend__item-subtitle">{subtitle}</p>

            </Link>
        </div>
    )
}

export default function CaseBox({ items }) {
    return (
        <div class="brend" id="brend">
            {items.map((item, index) => {
                return <Case key={index} {...item} />
            })}
        </div>
    )
}
