import React from 'react'
import './404Page.modules.scss'
import { Link } from 'react-router-dom'
import o_ from '../../assets/img/o.svg'
import { ThemeContext } from '../../App'
import { useDispatch } from 'react-redux'


export default function _404Pages() {

    const dispath = useDispatch()
    const setTheme = (theme) => {
        dispath({ type: 'theme/setTheme', payload: theme })
    }
    setTheme('dark')


    return (
        <section class="section__erorr">
            <div class="container">

                <div class="erorr">


                    <div class="section__title-num">

                        <p class="section__title-num-p" id="active">4</p>

                        <span>
                            <img src={o_} class="section__title-num-svg" alt="svg" />

                        </span>

                        <p class="section__title-num-p">4</p>

                    </div>

                    <p class="erorr__text">sorry, the page is under construction </p>

                    <Link to={"/"} class="erorr__btn">come back</Link>

                </div>

            </div>
        </section>
    )
}
