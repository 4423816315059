import React from 'react'
import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';
import './Doc.modules.scss'

const file = 'https://s3.timeweb.com/a9fc5923-b2b-opalub/DAZ_приложение_2.docx.pdf'// word.docx
let type = 'pdf' // word.docx
// const type = 'docx' // word.docx
// const type = 'png'

export default function Doc() {

    return (
        <FileViewer
            
            fileType={type}
            filePath={file}
            // errorComponent={CustomErrorComponent}
            onError={()=>{}} />
    );
}