import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './ContactPage.modules.scss'
import button_copm from '../../assets/img/button_copm.svg'
import Input from 'antd/es/input/Input';

import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import UploadFile from '../UploadFile/UploadFile';
import { ThemeContext } from '../../App';
import Translation_ from '../../Translater';
import { useTranslation } from 'react-i18next';
import { Alert, message } from 'antd';
import { useDispatch } from 'react-redux';






export default function ContactPage() {
    const dispath = useDispatch()
    const setTheme = (theme) => {
        dispath({ type: 'theme/setTheme', payload: theme })
    }
    setTheme('light')

    document.body.classList.remove('locked')
    const typesRef = React.useRef(null)
    const budgetRef = React.useRef(null)

    const tpRef = React.useRef(null)
    const bgRef = React.useRef(null)

    const [useType, setUseType] = React.useState('none')
    const [useBudget, setUseBudget] = React.useState('none')
    const [messageApi, contextHolder] = message.useMessage();


    useEffect(() => {
    }, [])

    const { t } = useTranslation()
    const project_types = [
        t('contactPage.project_types.0'),
        t('contactPage.project_types.1'),
        t('contactPage.project_types.2'),
        t('contactPage.project_types.3'),
        t('contactPage.project_types.4'),
        t('contactPage.project_types.5'),
    ]
    // console.log(project_types);
    const budget_types = [
        t('contactPage.budget_types.0'),
        t('contactPage.budget_types.1'),
        t('contactPage.budget_types.2'),
        t('contactPage.budget_types.3'),
        t('contactPage.budget_types.4'),
    ]
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Форма успешно отправлена',
        });
    };
    const select_project_type = (e) => {
        typesRef.current.childNodes.forEach((item, index) => {
            item.classList.remove('active')
        })
        tpRef.current.value = e.target.innerText
        e.target.classList.add('active')
        setUseType(e.target.innerText)
    }
    const select_budget_type = (e) => {
        budgetRef.current.childNodes.forEach((item, index) => {
            item.classList.remove('active')
        })
        e.target.classList.add('active')
        bgRef.current.value = e.target.innerText
        setUseBudget(e.target.innerText)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('SendForm');
        // https://h.albato.com/wh/38/1lful5p/mVWmwB_WGZZ4-mcOkUUkqgOPmN75KCYnQldPqAkHFhA/

        const myForm = event.target;
        const formData = new FormData(myForm);

        // отправь webhook на https://h.albato.com/wh/38/1lful5p/mVWmwB_WGZZ4-mcOkUUkqgOPmN75KCYnQldPqAkHFhA/
        // с данными formData
        // и в ответ получи сообщение "Форма успешно отправлена"

        fetch("https://h.albato.com/wh/38/1lful5p/mVWmwB_WGZZ4-mcOkUUkqgOPmN75KCYnQldPqAkHFhA/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                console.log("Form successfully submitted")
                success()
            })
            .catch((error) => alert(error));

    };

    return (
        <section class="section__contact">
            {contextHolder}
            <div class="container">

                <form class="contact"
                    netlify
                    onSubmit={handleSubmit}
                    data-netlify="true" name="contact">

                    <p class="contact__title">
                        <Translation_ type='html' path="contactPage.title" />
                    </p>


                    <p class="contact__text">
                        <Translation_ path="contactPage.desc" />
                    </p>

                    <div class="contact__type">

                        <p class="contact__type-text">
                            <Translation_ path="contactPage.projectT" />
                        </p>

                        <div class="contact__type-buttons" ref={typesRef}>
                            <input type="hidden" name='type' ref={tpRef} />

                            {project_types.map((item, index) => {
                                return (
                                    <button onClick={select_project_type} type='button' class="contact__type-buttons-btn">
                                        {item}
                                    </button>
                                )
                            })}
                        </div>

                    </div>

                    <div class="contact__type">

                        <p class="contact__type-text">
                            <Translation_ path="contactPage.buggetT" />
                        </p>

                        <div class="contact__type-buttons" ref={budgetRef}>
                            <input type="hidden" name='budget' ref={bgRef} />
                            {budget_types.map((item, index) => {
                                return (
                                    <button onClick={select_budget_type} type='button' class="contact__type-buttons-btn">
                                        {item}
                                    </button>
                                )
                            })}

                        </div>

                    </div>

                    <div class="contact__type">

                        <p class="contact__type-text">
                            <Translation_ path="contactPage.about" />
                        </p>

                        <TextArea
                            className='contact__type-subtitle'
                            name="message"
                            showCount
                            maxLength={200}
                            style={{
                                height: 120,
                                resize: 'none',
                                minHeight: '224px'
                            }}
                            // onChange={ }
                            placeholder={t("contactPage.writeIt")}
                        />


                        <div class="uploadFile contact__type-subtitle">
                            <UploadFile />
                        </div>

                    </div>

                    <div class="contact__type">

                        <p class="contact__type-text">Contact:</p>

                        <div class="contact__type-buttons">

                            <Input size="large" required name="name" className='contact__type-buttons-inp' placeholder={t("contactPage.form.fn")} prefix={<UserOutlined />} />
                            <Input size="large" required name="mail" className='contact__type-buttons-inp' placeholder={t("contactPage.form.email")} prefix={<MailOutlined />} />
                            <Input size="large" required name="phone" className='contact__type-buttons-inp' placeholder={t("contactPage.form.phone")} prefix={<PhoneOutlined />} />
                        </div>

                    </div>


                    <div class="contact__type-footer">

                        <button
                            style={{
                                background: 'inherit'
                            }}
                            type='submit' href="#" class="start__copman-button" id="start__copman-button">
                            <img src={button_copm} class="start__copman-button-svg" alt="svg" />
                            <p class="start__copman-button-text">
                                <Translation_ path="contactPage.send" />
                            </p>
                        </button>

                        <div class="contact__type-footer-info">
                            <p class="contact__type-footer-info-text">
                                <Translation_ path="contactPage.acceptPP" />
                            </p>
                            {/* <hr class="contact__type-footer-info-hr" /> */}
                        </div>

                    </div>




                </form>

            </div>
        </section >
    )
}
