import React, { useEffect, useMemo } from 'react';
import { TranslationProvider } from 'i18nano';
// import { load } from './load';
import { BrowserRouter as Router, Routes, Route, redirect, Redirect, useLocation, Navigate, useNavigate } from "react-router-dom";
import HomePage from './pages/HomePage';
import _404Page from './pages/_404Page';
import './assets/scss/global.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ContactsPage from './pages/ContactsPage';
import { HashLoader } from 'react-spinners';
import BoobleLoader from './components/BoobleLoader/BoobleLoader';
import BusinessWebsite from './pages/Business/BusinessWebsite';
import BlogEditor from './components/Blog/BlogPage/BlogPage';
import SiteMaker from './components/SiteMaker/SiteMaker';
import Blogs from './components/Blog/Blogs/Blogs';
import BlogPage from './components/Blog/BlogPage/BlogPage';
import Doc from './components/Doc/Doc';
import { Provider } from 'react-redux';
import { store } from './store';
import StartAppWebApp from './pages/StartUp/startupWebApp';
import WebAppForBusiness from './components/Services/WebAppForBusiness/WebAppForBusiness';
import { gsap } from "gsap";
import GsapContainer from './modules/GsapContainer/GsapContainer';
import Cases from './pages/Cases';

// задать тему и подкючить к провайдеру


const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  doc.style.setProperty('--app-height_mh', `${window.innerHeight - 71}px`)
}
window.addEventListener('resize', appHeight)
// appHeight()



const translations = {
  // dynamic import static json
  'en': () => import('./translations/en.json'),
  'ru-ru': () => import('./translations/ru.json'),
};

export const ThemeContext = React.createContext('light')
export const LoaderContext = React.createContext('')


const routers = [
  {
    key: 'main',
    exact: true,
    path: "/",
    element: HomePage
  },
  {
    key: 'contact',
    exact: true,
    path: "/contact",
    element: ContactsPage
  },

  {
    key: 'work',
    exact: true,
    path: "/work",
    element: Cases
  },


  // buisness
  {
    key: 'web-app-development',
    exact: true,
    path: "/web-app-development/",
    element: WebAppForBusiness
  },

  // Start Up
  {
    key: 'startup-app-development',
    exact: true,
    path: "/startup-app-development/",
    element: StartAppWebApp
  },

  // blog
  {
    key: 'blog',
    exact: true,
    path: "/blog/",
    element: Blogs,
    mainBlock: false
  },

  {
    key: 'blog',
    exact: true,
    path: "/blog/:id",
    element: BlogPage,
    mainBlock: false,
  },
  // 

  {
    key: 'SiteMaker',
    // exact: false,
    path: "/SiteMaker/:id",
    element: SiteMaker,
    mainBlock: false
  },

  {
    key: 'Doc',
    // exact: false,
    path: "/Doc",
    element: Doc,
    mainBlock: false
  },

  {
    key: '404',
    path: "*",
    element: _404Page
  }
]

function App() {
  // контектсы
  const [theme, setTheme] = React.useState('light')
  const [loadingPage, setLoadingPage] = React.useState(false)
  const themeVal = useMemo(() => ({ theme, setTheme }), [theme])
  const loaderVal = useMemo(() => ({ loadingPage, setLoadingPage }), [loadingPage])

  const [modalActive, setModalActive_] = React.useState(false);

  function setModalActive() {

    setModalActive_(prev => true)
  }

  function MainBox(props) {

    let { header, footer } = props || true
    header = header ?? true
    footer = footer ?? true
    return (
      <>
        {header && <Header setModalActive={setModalActive} />}
        {/* <Modal modalActive={modalActive} SetModalActive={setModalActive_} /> */}
        {props.children}
        {footer && <Footer setModalActive={setModalActive} />}
      </>
    )
  }

  return (
    <>


      <Provider store={store}>
        <LoaderContext.Provider value={loaderVal}>
          <TranslationProvider
            unstable_transition={true}
            language="en"
            translations={translations}>
            <Router>
              <GsapContainer>
                <BoobleLoader active={loadingPage} setActive={setLoadingPage} count={8} color={'black'} duration={1} />
                <Routes>
                  {routers.map((item, index) => {
                    return (
                      <Route
                        key={item.key}
                        exact={item.exact}
                        path={item.path}
                        element={
                          item.mainBlock !== false ?
                            <MainBox footer={item.footer} header={item.header}>
                              <item.element setModalActive={setModalActive} />
                            </MainBox>
                            :
                            <item.element setModalActive={setModalActive} />
                        }
                      />
                    )
                  })}
                </Routes>
              </GsapContainer>
            </Router>
          </TranslationProvider>
        </LoaderContext.Provider>
      </Provider>
    </>
  );
}

export default App;
