import { Button, Dropdown, Select, Space } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { scss } from './LanguageSwitch.modules.scss'
import { redirect } from 'react-router-dom';
const ButtonLang = ({ lang }) => {
    let text = lang.split('-')[0]
    return (
        <Space>
            <div className='flag' flag={lang}></div>
            <div className='menu__item-link'>{text}</div>
        </Space>)
}

export default function LanguageSwitch() {
    const { t, i18n } = useTranslation();
    const [lg, setLg] = React.useState('en');
    // при загрузке страницы получить язык из localStorage
    const all_lg = Object.keys(i18n.options.resources);

    React.useEffect(() => {
        // Check Lang Attr ru-ru.itab.studio - ru | en-ru.itab.studio - en
        try {
            const lang = window.location.href.split('/')[2].split('.')[0]
            changeLg(lang)
        } catch {
            changeLg('en')
        }
    }, [, lg]);


    const RedirectToLang = (lg) => {
        let urlToRedirectArr = window.location.href.split('/');
        let urlToRedirect = ''
        if (lg !== 'en') {
            urlToRedirect = urlToRedirectArr[0] + '//' + lg + '.' + 'itabstudio.com' + '/' + urlToRedirectArr[3];
        } else {
            urlToRedirect = urlToRedirectArr[0] + '//' + 'itabstudio.com' + '/' + urlToRedirectArr[3];
        }
        window.location.replace(urlToRedirect)
    }


    const changeLg = (lg, btn = false) => {
        if (!btn) {
            if (all_lg.includes(lg)) {
                i18n.changeLanguage(lg);
                // сохранить в localStorage
                localStorage.setItem('lng', lg);
                setLg(lg)
            } else {
                changeLg('en')
            }
        } else {
            RedirectToLang(lg)
        }
    }

    // получить все языки 

    const get_lgs = () => {
        let a = []
        console.log(all_lg);
        all_lg.map((lang, i) => {

            a.push({
                value: lang,
                label: (
                    <ButtonLang lang={lang} />
                ),
            })
        })
        return a
    }
    let items = get_lgs()



    return (
        <div className='menu__item'>
            <Select
                value={lg}
                style={{
                    width: 120,
                }}
                onChange={(lang) => {
                    changeLg(lang, true);
                }}
                bordered={false}
                options={items}
            />
            {/* <select value={lg} onChange={(event) => {
                changeLg(event.target.value);
            }}>
                {all_lg.map((lang) => (
                    <option key={lang} value={lang}>
                        {lang}
                    </option>
                ))}
            </select> */}
        </div>
    );
}
