import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import './Animation.modules.scss'
import { createUseStyles } from "react-jss";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default function Animation({ type = 'bottomSwipe', duration = 500, delay = 500, customAnim = {}, onScreen = false, children }) {

    let anim_name = `anim`;
    const useStyles = createUseStyles({
        [anim_name]: {
            animation: `${type} ${duration / 1000}s ease-in-out, ${delay / 1000}s`
        },
        [`${anim_name}R`]: {
            animation: `${type} ${duration / 1000}s ease-in-out, ${delay / 1000}s reverse`
        }
    });
    const classes = useStyles();


    const [animActive, setaAimActive] = React.useState(false)


    // при достижении элемента в viewport

    const ref = React.useRef(null)
    const _onScreen = useOnScreen(ref, -100)
    React.useEffect(() => {
        if (_onScreen) {
            setTimeout(() => {
                setaAimActive(true)
            }, delay)
        } else {
            if (onScreen) {
                setaAimActive(false)
            }
        }

    }, [_onScreen, onScreen])



    return (
        <div
            ref={ref}
            className={`animationBlock 
                ${animActive ? `active ${classes[anim_name]}` : ''}
            `}>
            {children}
        </div>
    )
}

function useOnScreen(ref, rootMargin = 0, onScreen = false) {
    let rootM = `${rootMargin}px`;
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                'rootMargin': rootM,
                // 'threshold': 0.5
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            // observer.unobserve(ref.current);
        };

    }, []); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
}




export function AnimationGSAP({ children }) {
    const anmRef = useRef(null);
    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            console.log('anmRef', anmRef);
            gsap.fromTo(anmRef.current,
                {
                    y: 100,
                    opacity: 0,
                    scrollTrigger: {
                        trigger: anmRef.current,
                        pin: true,
                        start: 'bottom +=100',
                        duration: { min: 3.8, max: 3.5 },
                        // end: 'top 20%',
                        scrub: 1,
                        // показывать отметки
                        markers: { startColor: "white", endColor: "white", fontSize: "18px", fontWeight: "bold", indent: 20 },
                    },
                },
                {
                    y: 0,
                    opacity: 1,
                }
            )
        }, anmRef); // <- Scope!
        return () => ctx.revert(); // <- Cleanup!
    }, []);
    return (
        <div ref={anmRef}>
            {children}
        </div>);
}