import fetch from 'node-fetch'


export default async function getNewsArea(post_id = 1) {
    console.log('post_id', post_id);
    let BlogState = ''
    var requestOptions = {
        method: 'GET',
        redirect: 'manual'
    };

    let a = await fetch(`http://api.itab.studio/v2/post/${post_id}`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
    return a
}

function getNewsAreas(account = '@fruitourist', post_id = 'AvpR_ETiBrC') {
    let BlogState = ''
    var requestOptions = {
        method: 'GET',
        redirect: 'manual'
    };

    let art = fetch(`https://teletype.in/${account}/${post_id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            // получить <article>   </article>
            let article = result.match(/<article(.*?)<\/article>/g)
            // console.log(article);
            return article[0]
        })
        .catch(error => console.log('error', error));
    return art
}


// getNewsAreas()