import React from 'react'
import { useHref, useLinkClickHandler, useNavigate } from 'react-router-dom'
import { LoaderContext } from '../../App';
// import styled from 'styled-components';


export function Links({ to, onClick, target, rest, children }) {
    // const StyledLink = styled("a", { color: "fuchsia" });
    // const navigate = useNavigate();

    const handleClick = (event) => {
        console.log('handleClick', event);
    }

    return (
        <a
            {...rest}
            href={to}
            onClick={(event) => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
        >
            {children}
        </a>
    )
}




export const Link = React.forwardRef(
    (
        {
            onClick,
            replace = false,
            state,
            target,
            to,
            ...rest
        },
        ref
    ) => {
        const { loadingPage, setLoadingPage } = React.useContext(LoaderContext)
        let href = useHref(to);
        let handleClick = useLinkClickHandler(to, {
            replace,
            state,
            target,
        });

        let tv = (event) => {
            console.log('tv', event);
            setLoadingPage(prev => true)
            // setTimeout(() => {
            onClick?.(event);
            if (!event.defaultPrevented) {
                handleClick(event);
            }
            // }, 3000);

        }

        return (
            <a
                {...rest}
                href={href}
                onClick={tv}
                ref={ref}
                target={target}
            />
        );
    }
);