
import React from 'react';
import { Helmet } from 'react-helmet';
import WebAppForStartup from '../../components/Services/WebAppForStartup/WebAppForStartup';

function StartAppWebApp({ setModalActive }) {
    document.body.classList.remove('locked')
    return (
        <>
            <Helmet
                htmlAttributes={{ "lang": "en", "amp": undefined }} // amp takes no value
                title="Web app development services for Startups | Web Application Dev Company"
                // titleTemplate="ITAB: %s"
                // defaultTitle="ITAB"
                description="Grow Your Business and Expand your Reach with ITAB - Web Development & Automation in the Middle East."
                meta={[
                    { "name": "description", "content": "Grow Your Business and Expand your Reach with ITAB - Web Development & Automation in the Middle East." },
                    { "property": "og:type", "content": "website" },
                    { property: "og:image", content: "../assets/posters/poster_contact.png" },
                    { property: "og:image:secure_url", content: "../assets/posters/poster_contact.png" },
                    { property: "og:image:type", content: "image/png" }
                ]}
                // link={[
                //     { "rel": "canonical", "href": "http://mysite.com/example" },
                //     { "rel": "apple-touch-icon", "href": "http://mysite.com/img/apple-touch-icon-57x57.png" },
                //     { "rel": "apple-touch-icon", "sizes": "72x72", "href": "http://mysite.com/img/apple-touch-icon-72x72.png" }
                // ]}
                script={[
                    { "src": "http://include.com/pathtojs.js", "type": "text/javascript" },
                    { "type": "application/ld+json", innerHTML: `{ "@context": "http://schema.org" }` }
                ]}
            />
            <WebAppForStartup setModalActive={setModalActive} />
        </>
    );
}

export default StartAppWebApp;