import React from 'react'
import Translation_ from '../../Translater'
import './CasesPage.modules.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Video from '../Video/Video'

const CaseBlock = (obj, first = true) => {
  const refVideo = React.useRef(null);
  const [onHover, setOnHover] = React.useState(false);
  let width = window.innerWidth
  return (
    <Link
      gsapType={first && 'bottomSideB'} scrollTriger='false' gsapDuration='0.6' gsapRepeat='false'
      className='caseLink' to={obj.to}
      onMouseOver={() => {
        try {
          console.log('width', width);
          if (width < 560) return;
          setOnHover(prev => true)
          refVideo.current.play();
        } catch (e) { }
      }}

      onMouseOut={() => {
        try {
          setOnHover(prev => false)
          refVideo.current.pause();
          refVideo.current.currentTime = 0;
        } catch (e) { }
      }}

    >
      <div className='case__img'>
        <div className='imgs'>
          <Video
            onHover={onHover}
            reff={refVideo}
            poster={obj.img}
            video={obj.video} />
        </div>
      </div>
      <div className='case__content'>
        <div className='case__title'>
          <Translation_ type='html' path={obj.title} />
        </div>
        <div className='case__text'>
          <Translation_ type='html' path={obj.desc} />
        </div>
      </div>
    </Link>
  )
}

export default function CasesPage() {
  const { t, i18n } = useTranslation();
  const lang_arr = i18n.logger.options.resources[i18n.language].translation
  let cases = lang_arr.cases ? lang_arr.cases : []
  let first = cases[0] ? cases[0] : {}
  // console.log(cases);
  // удалить первый элемент массива
  let leftCases = cases.slice(1, Math.ceil(cases.length / 2))
  let rightCases = cases.slice(Math.ceil(cases.length / 2), cases.length)
  return (
    <section class="section__contact">
      <div class="container">

        <div class="contact">
          <p class="contact__title">
            <Translation_ type='html' path="casesPage.title" />
          </p>

          <div className='cases'>
            <div className='case_left'>
              {first && CaseBlock(first, false)}
              {leftCases.map((item, index) => CaseBlock(item))}
            </div>
            <div className='case_right'>
              {rightCases.map((item, index) => CaseBlock(item))}
            </div>

          </div>

        </div>

      </div>
    </section >
  )
}
