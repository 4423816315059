import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap';
import './BoobleLoader.modules.scss'
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/img/header__logo.svg'
import logo_dark from '../../assets/img/header__logo-black.svg'
import { ThemeContext } from '../../App';
import { useDispatch, useSelector } from 'react-redux';


function LogoBlock({ duration = 1.5, color = '#fff', theme = 'light' }) {
  let boobleClass = `booble`;
  const useStyles = createUseStyles({
    [boobleClass]: {
      // backgroundColor: '#75FB5E',
      width: '100%',
      height: '100%',
      display: 'flex',
      borderRadius: '100%',
      position: 'absolute',
      zIndex: '9999',
      alignItems: 'center',
      justifyContent: 'center',
      willСhange: "transform"
    },
    dv: {
      width: '30%',
      height: '30%',
      borderRadius: '100%',
      display: 'flex',
      backgroundColor: color,
      justifyContent: 'center',
      padding: '20px',
      willСhange: "transform"
    },
    img: {
      width: '100%',
      height: '100%',
      willСhange: "transform"
    }
  });
  const classes = useStyles();

  const anmRef = useRef(null);
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.fromTo(anmRef.current,
        {
          scale: '0',
        },
        {
          scale: '1',
          duration: duration,
          yoyo: true,
          repeat: 1,
        }
      )
    }, anmRef); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  return (
    <div className={classes[boobleClass]} ref={anmRef}>
      <div className={classes.dv}>
        <img className={classes.img} src={theme === 'dark' ? logo : logo_dark} alt="img" />
        {/* <img src={logo} alt="img" /> */}
      </div>
    </div>
  );
}

function BoobleBlock({ poz, color = '#1d577c', duration = 1.5, i=0 }) {
  let boobleClass = `booble`;
  const useStyles = createUseStyles({
    [boobleClass]: {
      backgroundColor: color,
      width: '10vw',
      height: '10vw',
      borderRadius: '100%',
      position: 'absolute',
      zIndex: `${99 + i}`,
      willСhange: "transform"
    },
  });
  const classes = useStyles();

  const anmRef = useRef(null);
  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.fromTo(anmRef.current,
        {
          scale: '0',
          left: `${poz}%`,
          top: 'random(0, 80, 8)%',
        },
        {
          scale: '9',
          duration: duration,
          // delay: 0,
          yoyo: true,
          repeat: 1,
        }
      )
    }, anmRef); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  return (
    <div className={classes[boobleClass]} ref={anmRef}></div>
  );
}

export default function BoobleLoader({ color, count = 8, duration = 1 }) {
  const theme = useSelector(state => state.theme.theme)
  const dispath = useDispatch()
  const setTheme = (theme) => {
    dispath({ type: 'theme/setTheme', payload: theme })
  }
  // const theme = 'dark'
  const [active, setActive] = React.useState(true);
  const [prevPath, setPrevPath] = React.useState('');
  const [vis, setVis] = React.useState(true);
  const location = useLocation();

  // 
  function generatePositionBubbles(count) {
    let arr = [];
    // созбрать массив с позициями где находятся баблики распределенные равномерно
    for (let i = 0; i < count; i++) {
      arr.push(i * 95 / count);
    }
    return arr;
  }


  useEffect(() => {
    if (location.pathname !== prevPath) {
      setVis(true);
      setActive(true);
      setTimeout(() => {
        setVis(false);
        setActive(false);
      }, duration * 1000);
      // прошлый путь
      setPrevPath(prev => location.pathname);
    }
  }, [location]);



  let count_bubbles = count;
  let BooblePoz = generatePositionBubbles(count_bubbles);
  
  color = theme === 'dark' ? 'white' : 'black';

  return (
    <div className='BoobleLoader'
      style={{
        visibility: vis ? 'visible' : 'hidden',
      }}
    >
      {active &&
        <LogoBlock color={color} theme={theme} duration={duration / 2} />
      }
      {active && count_bubbles > 0 && (BooblePoz).map((_, i) => {
        return (<BoobleBlock active={active} color={color} poz={_} duration={duration / 2} i={i} />)
      })}
    </div >
  )

}
