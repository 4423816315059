import React from 'react'
import { FieldTimeOutlined } from '@ant-design/icons'
import s from './MainBlock.module.scss'
import border__static from '../../../../assets/img/brend__item-img2.jpg'
import { Link } from 'react-router-dom'
import { LoaderContext } from '../../../../App'
import { Skeleton } from 'antd'
// import start12 from '../../../../assets/img/start12.svg'
// import start123 from '/assets/img/start12.svg'

const SleketonNews = () => {
    return (
        <div className={s['desc']}>
            <Skeleton.Input active style={{
                width: "100%"
            }}>
                <Link to={'64316f8440650e3cad293fb5'} className={s['a']}>
                    <span className={s['dsc']}>
                        Что такое автоматизация, и зачем она нужна бизнесу.
                    </span>
                </Link>
            </Skeleton.Input>
            <Skeleton.Input active>
                <div className={s['time']}>
                    <FieldTimeOutlined />
                    <span> 2 минуты</span>
                </div>
            </Skeleton.Input>
        </div>
    )
}
const SleketonNewsIcon = () => {
    return (
        <div className={s['main']}>
            <Skeleton.Image active style={{
                width: "100%",
                height: "200px"
            }}>
                <Link to={''}>
                    <img src={border__static} />
                </Link>
            </Skeleton.Image>
            <div className={s['desc']}>
                <Skeleton.Input active style={{
                    width: "100%"
                }}>
                    <Link to={''} className={s['a']}>
                        <span className={s['dsc']}>
                            {''}
                        </span>
                    </Link>
                </Skeleton.Input>
                <Skeleton.Input active style={{
                    width: "40%"
                }}>

                    <div className={s['time']}>
                        <FieldTimeOutlined />
                        <span> {''}</span>
                    </div>
                </Skeleton.Input>
            </div>
        </div >
    )
}

const MainNews = ({ post, loading }) => {
    post = post || {}
    console.log(post);
    let { title, timeToRead, _id } = post
    console.log(loading);

    return (
        <>
            {loading ?
                title && timeToRead && _id ?
                    <div className={s['main']}>
                        <Link to={post._id}>
                            <img src={border__static} />
                        </Link>

                        <div className={s['desc']}>
                            <Link to={post?._id} className={s['a']}>
                                <span className={s['dsc']}>
                                    {post?.title}
                                </span>
                            </Link>
                            <div className={s['time']}>
                                <FieldTimeOutlined />
                                <span> {post?.timeToRead}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                :
                <SleketonNewsIcon />
            }
        </>
    )
}


const ConterNews = ({ loading, post }) => {
    console.log(' ConterNews loading', post);
    return (
        <>
            {
                loading ?
                    <div className={s['desc']} >
                        <Link to={post._id} className={s['a']}>
                            <span className={s['dsc']}>
                                {post.title}
                            </span>
                        </Link>

                        <div className={s['time']}>
                            <FieldTimeOutlined />
                            <span> {post.timeToRead}</span>
                        </div>
                    </div >
                    :
                    <SleketonNews />
            }
        </>
    )
}


export default function MainBlock(props) {
    let { posts, loading } = props
    let posts_1_3 = posts.slice(1, 4)
    return (
        <div className={s['HeaderNews']}>

            <MainNews post={posts[0]} loading={loading} />
            <div className={s['dopNewses']}>
                {/* for post 4 */}
                {posts_1_3.map((post, index) => {
                    return (<ConterNews post={post} loading={loading} />)
                })}
                {/* <ConterNews loading={loading} /> */}
                {/* <ConterNews loading={loading} /> */}

            </div>
        </div>
    )
}
