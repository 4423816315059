import React from 'react'
import { Link } from 'react-router-dom'
import "./Footer.scss"
import '../../assets/scss/global.scss'
import Translation_ from '../../Translater.js'

export default function Footer({ setModalActive }) {
  return (
    <>
      <section class="section__info">
        <Link to={"/contact/"}>


          <div class="info__hover"></div>

          <div class="container">

            <div class="info">
              <p class="info__title">
                <Translation_ path='footer.contact1' />
              </p>
              <p class="info__subtitle section__title">
                <Translation_ type='html' path='footer.contact2' />
              </p>
            </div>

          </div>


        </Link>


      </section>
      <footer class="section__footer">
        <div class="container">

          <div class="footer">

            <div class="footer__ul">

              <li href="#" class="footer__ul-title"><Translation_ path='footer.location' /></li>


              <label class="link__hover">
                <li href="#" class="footer__ul-link">UAE, Dubai</li>
                <hr class="footer__ul-link-hr" />
              </label>

              <label class="link__hover">
                <li href="#" class="footer__ul-link">Thailand, Bangkok</li>
                <hr class="footer__ul-link-hr" />
              </label>

              <label class="link__hover">
                <li href="#" class="footer__ul-link">Russian Federation, Moscow</li>
                <hr class="footer__ul-link-hr" />
              </label>

            </div>

            <div class="footer__ul">

              <li class="footer__ul-title"><Translation_ path='footer.portfolio' /></li>

              <label class="link__hover">
                <a href="#" class="footer__ul-link">Instagram</a>
                <hr class="footer__ul-link-hr" />
              </label>

              <label class="link__hover">
                <Link to={"/"} class="footer__ul-link">Behance</Link>
                <hr class="footer__ul-link-hr" />
              </label>

              <label class="link__hover">
                <Link to={"/asa"} class="footer__ul-link">Linkedln</Link>
                <hr class="footer__ul-link-hr" />
              </label>

            </div>


            <div class="footer__ul">

              <li class="footer__ul-title">
                <Translation_ path='footer.getInTouch' />
              </li>

              {/* <label class="link__hover">
                <Link to={"tel:+66 957 526 101"} class="footer__ul-link">+66 957 526 101</Link>
                <hr class="footer__ul-link-hr" />
              </label> */}

              <label class="link__hover">
                <a href={"mailto:contact@itab.studio"} class="footer__ul-link">contact@itab.studio</a>
                <hr class="footer__ul-link-hr" />
              </label>

              <label class="link__hover">
                <Link to={"/contact"} preventScrollReset={false} class="footer__ul-link">Contact us</Link>
                <hr class="footer__ul-link-hr" />
              </label>

            </div>

          </div>

          <div class="footer__bottom">

            <p class="footer__bottom-text">©2023, ITab Studio Inc.</p>

            <ul class="footer__bottom-links">

              <li class="footer__li">
                <hr class="footer__bottom-links-hr" />

                <label class="footer__label">
                  <Link href="#" class="footer__bottom-links-link">Sitemap</Link>

                  <hr class="footer__label-hr" />

                </label>

              </li>

              <li class="footer__li">
                <hr class="footer__bottom-links-hr" />

                <label class="footer__label">
                  <Link href="#" class="footer__bottom-links-link">Privacy Policy</Link>

                  <hr class="footer__label-hr" />

                </label>

              </li>

              <li class="footer__li">
                <hr class="footer__bottom-links-hr" />
                <label class="footer__label">
                  <Link href="#" class="footer__bottom-links-link">Terms of Use</Link>

                  <hr class="footer__label-hr" />

                </label>
              </li>

              <li class="footer__li">
                <hr class="footer__bottom-links-hr" />
                <label class="footer__label">
                  <Link href="#" class="footer__bottom-links-link">Cookie Policy</Link>

                  <hr class="footer__label-hr" />

                </label>
              </li>
            </ul>

          </div>

        </div>
      </footer>
    </>
  )
}
