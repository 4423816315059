import React, { useState, Suspense } from 'react'
import { Link, useLocation } from 'react-router-dom'
import "./Header.modules.scss"
// import { Translation_ } from 'i18nano';

import logo from '../../assets/img/header__logo.svg'
import logo_dark from '../../assets/img/header__logo-black.svg'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { ThemeContext } from '../../App';

import Translation_ from '../../Translater';
import { useDispatch, useSelector } from 'react-redux'
export default function Header({ setModalActive }) {
  const { pathname } = useLocation();
  console.log('UPDATE HEADER')

  React.useEffect(() => {
    window.scrollTo(0, 0, 'smooth');
  }, [pathname]);

  const [menuActive, setMenuActive] = useState(false)
  const [menuPopupActive, setMenuPopupActive] = useState(false)
  const theme = useSelector(state => state.theme.theme)
  const dispatch = useDispatch()
  const setTheme = (theme) => {
    dispatch({ type: 'theme/setTheme', payload: theme })

  }
  const mn = React.useRef(null)


  function active_menu() {
    // console.log('active_menu');
    setMenuActive(prev => !prev)
    const body = document.querySelector('body').classList.toggle('locked')
    const menu_item = document.querySelectorAll('.menu__item').forEach((item, index) => {
      if (!menuActive) {
        item.style.opacity = '0'
        item.style.transform = `translateX(${(index + 1) * -12}vw)`
        // пауза в 0.1 секунды
        setTimeout(() => {
          item.style.opacity = '1'
          item.style.transform = 'translateX(0)'
        }, index * 100)
      } else {
        item.style = ''
        mn.current.style = {}
        setMenuPopupActive(false)
      }
    })
  }

  function upsetMenuParams() {
    setMenuPopupActive(prev => !prev)
    if (mn.current) {
      if (!menuPopupActive) {
        // таймаут для того чтобы сработало условие в active_menu
        setTimeout(() => {
          mn.current.style.height = 'auto'
        }, 250)

      } else {
        mn.current.style = {}
      }
    }
  }

  return (
    <>
      <header class='header' theme={theme ?? 'light'}>

        <div class="container hd">


          <nav class="navbar">
            <div class="logo">
              <Link to={"/"} relative="path">
                <img src={theme == 'dark' || theme == 'lightNews' ? logo_dark : logo} alt="img" />
              </Link>
            </div>

            <div class={`burger ${menuActive ? 'active-burger' : ''}`} onClick={active_menu}>
              <span></span>
            </div>
          </nav>
          <div class={`rheader ${menuActive ? 'active' : ''}`} style={{

          }}>
            <ul class={`menu ${menuActive ? 'active' : ''} ${menuPopupActive ? 'active_block' : ''}`}
              ref={mn}
            >
              <div class="menu__item secondary-nav" onClick={upsetMenuParams}>
                <span href="/services" class="menu__item-link">
                  <Translation_ path="header.Services" />
                </span>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
                  style={menuPopupActive ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }}
                >
                  <path d="M12 17.5L7 10.5H17L12 17.5Z" fill="black"></path>
                </svg>
                <div class={`cols ${menuPopupActive ? 'is-visible' : ''}`}>
                  <div class="col">
                    <strong><Translation_ path="header.popup.Startups" /></strong>

                    <ul class="viewport-process is-in-viewport page-enter-complete">

                      <li><Link to={"/startup-branding/"} onClick={(e) => {}}><Translation_ path="header.popup.Branding" /></Link></li>
                      <li><Link to={"/startup-ui-ux-design/"}><Translation_ path="header.popup.UI/UX Design" /></Link></li>
                      <li><Link to={"/startup-app-development/"}><Translation_ path="header.popup.MVP Development" /></Link></li>
                      <li><Link to={"/startup-web-design/"}><Translation_ path="header.popup.Website Design" /></Link></li>
                    </ul>
                  </div>
                  <div class="col">
                    <strong><Translation_ path="header.popup.Enterprise" /></strong>

                    <ul class="viewport-process is-in-viewport page-enter-complete">

                      <li><Link to={"/app-design/"}><Translation_ path="header.popup.App Design" /></Link></li>
                      <li><Link to={"/branding/"}>Branding</Link></li>
                      <li><Link to={"/design-systems/"}>Design Systems</Link></li>
                      <li><Link to={"/ui-ux-design/"}>UI/UX Design</Link></li>
                      <li><Link to={"/web-app-development/"}>Web Apps</Link></li>
                      <li><Link to={"/web-design/"}>Web Design</Link></li>
                    </ul>
                  </div>
                </div>

              </div>
              <Link to={"/work/"} class="menu__item"><div class="menu__item-link"><Translation_ path="header.Work" /></div></Link>
              <Link to={"/about/"} class="menu__item"><div class="menu__item-link"><Translation_ path="header.About" /></div></Link>
              <Link to={"/blog/"} class="menu__item"><div class="menu__item-link"><Translation_ path="header.Blog" /></div></Link>
              <Link to={"/contact/"} class="menu__item"><div class="menu__item-link"><Translation_ path="header.Contact" /></div></Link>
              <LanguageSwitch />
            </ul>
          </div>
        </div>

      </header>
    </>
  )
}
